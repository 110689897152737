import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SubscribeForm from '../components/SubscribeForm';

const NewsletterPage = ({ data }) => {

  return (
    <Layout>
      <SEO
        title="Newsletter"
      />

      <div className="subscribe-form__text"
        dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }}
      />

      <SubscribeForm />
    </Layout>
  )

}

export default NewsletterPage

export const query = graphql`
  query {
    wordpressPage(slug: { eq: "newsletter" }) {
      title
      content
    }
  }
`